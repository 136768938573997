<template>
  <div class="cUpload">
    <input
      ref="ddFile"
      type="file"
      :multiple="multiple"
      :accept="accept"
      @change="handleChange"
      class="file-upload"
    />
    <div class="defined">
      <div>
        <img :src="AddIcon" alt="" />
      </div>
    </div>
  </div>
</template>

<script setup>
import AddIcon from "@/assets/images/yijifen/upload-ticket.png";
import { defineProps,defineEmits } from 'vue'
  // 声明props
  const props = defineProps({
    accept: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    }
  })
  const emit = defineEmits(['onChangeFile'])
  const  handleChange = (e) => {
      const files = e.target.files;
      if (!files) return false;
      emit("onChangeFile", files);
      
    }

</script>
<style lang="scss" scoped>
.cUpload {
  position: relative;
  width: 76px;
  height: 70px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 6px;
  .file-upload {
    position: absolute;
    opacity: 0;
    width: 76px;
    height: 70px;
    top: 0;
    left: 0;
  }
  .defined {
    img {
      width: 76px;
      height: 70px;
    }
  }
}
</style>
