/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { getServer } from "./config/base";
import { BASE_URL } from "./config/config";


const aliServer = getServer(BASE_URL.pcUrl + "v2/");

/**
 * 获取上传凭证token
 */

export const getStsTokenApi = () => {
  return aliServer.post("process/get-sts-token", {});
};

