/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import OSS from "ali-oss";

import { showLoadingToast,closeToast } from 'vant';
import { getStsTokenApi } from "@/api/stsToken";


// 阿里上传文件方法
export const uploadFile = (files) =>
  new Promise((resolve, reject) => {
    getStsTokenApi()
      .then((res) => {
        if (res.code === 200) {
          const {
            userFolder,
            region,
            bucket,
            accessKeyId,
            accessKeySecret,
            securityToken,
            host,
          } = res.result;
          const client = new OSS({
            region,
            bucket,
            accessKeyId,
            accessKeySecret,
            stsToken: securityToken,
            endpoint: `${bucket}.${host}`,
            cname: true,
            secure: true,
          });

          showLoadingToast({
            message: '上传中...',
            forbidClick: true,
          });
          // return
          const uploaded = [];
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const fileName = files[i].name;
            const fileType = getValue(fileName)
            client
              .put(
                `${userFolder}/ticket/toy-mp-${getFileNameUUID()}.${fileType}`,
                file
              )
              .then((res) => {
                uploaded.push({
                  resourceName: fileName,
                  // storageKey: getCaption(res.url, `${userFolder}/ticket/`),
                  storageKey: res.url,
                });
                if (uploaded.length === files.length) {
                  closeToast()
                  resolve(uploaded);
                }
                console.log(res);
                console.log(getCaption(res.url, `${userFolder}/ticket/`));
              })
              .catch((err) => {
                console.log(err);
              });
          }
        } else {
          // ElMessage({
          //   message: res.msg,
          //   type: "warning",
          // });
        }
      })
      .catch((error) => console.log(error));
  });
// 截取字符串
export const getCaption = (str, userFolder) => {
  var index = str.lastIndexOf(userFolder); //获取 ${userFolder} 后边的字符串
  str = str.substring(index + userFolder.length, str.length);
  return str;
};

/**
 * 生成随机文件名称
 * 规则八位随机字符，加下划线连接时间戳
 */
export const getFileNameUUID = () => {
  function rx() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return `${+new Date()}-${rx()}${rx()}`;
};


// 获取最后一个 指定字符.后面的值
function getValue(url){
  var site = url.lastIndexOf(".");
  return url.substring(site + 1, url.length);
}

